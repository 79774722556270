import {
  createBrowserRouter
} from "react-router-dom";
import Configurations from "./views/configurations/configurations";
import Dashboard from "./views/dashboard/dashboard";
import SignIn from "./views/sign-in/sign-in";
import LeftSidebar from "./views/conversation/conversation";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./views/notfound/NotFound";
import Alerts from "./views/alerts/alerts";

export default createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute element={<Dashboard />} /> 
    )
  },
  {
    path: "/configurations",
    element: (
      <PrivateRoute element={<Configurations />} />  
    )
  },
  {
    path: "/conversation/all",
    element: (
      <PrivateRoute element={<LeftSidebar />} />  
    )
  },
  {
    path: "/alerts",
    element: (
      <PrivateRoute element={<Alerts />} />  
    )
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
