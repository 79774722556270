import { PropsWithChildren, useEffect } from "react";
import useSession from "../session/session_provider";
import HTTPClient from "./../../utils/http";

export default function HttpInterceptor({ children }: PropsWithChildren<{}>) {
  const { authToken } = useSession();

  useEffect(() => {
    HTTPClient.interceptors.request.clear();
    HTTPClient.interceptors.response.clear();
    HTTPClient.interceptors.request.use(
      function (config) {
        if(!config.headers.Authorization && authToken){
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    HTTPClient.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        return response;
      },
      function (error) {
        // if (error.response.status === 446) {
        // }
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        return Promise.reject(error);
      }
    );
  }, [authToken]);

  return <>{children}</>;
}
