import React from "react";
import { FormControl, FormLabel, TextField } from "@mui/material";

interface CustomTextFieldProps {
  id: string;
  label: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled";
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  name,
  placeholder,
  value,
  onChange,
  required = false,
  variant = "outlined",
}) => {
  return (
    <FormControl fullWidth>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <TextField
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth
        variant={variant}
      />
    </FormControl>
  );
};

export default CustomTextField;
