import React from 'react';
import { List } from '@mui/material';
import PersonListItem from './PersonListItem';


interface Person {
    id: number;
    name: string;
    date: string;
  }
  
interface PersonListProps {
  data: null | {
    sessionId: string;
    userId:    string;
    username:  string;
    firstName: string;
    lastName:  string;
    createdAt: string;
  }[];
  selectedPerson: string | null;
  setSelectedPerson: (id: string) => void;
}

const PersonList: React.FC<PersonListProps> = ({ data , selectedPerson, setSelectedPerson }) => {
  return (
    <List
      sx={{
        flex: 1,
        overflowY: "hidden", 
        scrollbarWidth: "thin", 
        scrollbarGutter: "stable",
        paddingRight: { xs: "12px", sm: "16px" }, 
        '&:hover': {
          overflowY: "auto", 
        },
      }}
    >
      {data?.map((session) => (
        <PersonListItem
          key={session.sessionId}
          sessiondId={session.sessionId}
          name={session.username || 'Anonymous'}
          timestamp={session.createdAt}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      ))}
    </List>
  );
};

export default PersonList;
