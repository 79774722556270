import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Sort, SortByAlpha, SportsRugbyTwoTone, SwapVert } from "@mui/icons-material";
import CustomTooltip from "../../components/CustomTooltip";

interface DateRangeModalProps {
  dateRange: DateRange<Dayjs>;
  tempRange: DateRange<Dayjs>;
  tempInput: string[];
  errorMessages: string[];
  selectedOption: string;
  predefinedOptions: string[];
  handleOptionClick: (option: string) => void;
  isApplyButtonDisabled: () => boolean;
  handleApply: () => void;
  handleCancel: () => void;
  handleStartDateChange: (newStartDate: Dayjs | null) => void;
  handleEndDateChange: (newEndDate: Dayjs | null) => void;
  toggleSorting: () => void;
}

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  dateRange,
  tempRange,
  tempInput,
  errorMessages,
  selectedOption,
  predefinedOptions,
  handleOptionClick,
  isApplyButtonDisabled,
  handleApply,
  handleCancel,
  handleStartDateChange,
  handleEndDateChange,
  toggleSorting,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          margin: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 0,
        }}
      >
        <TextField
          fullWidth
          sx={{ marginRight: 2 }}
          value={`${dateRange[0]?.format("MM/DD/YYYY") ?? ""} - ${
            dateRange[1]?.format("MM/DD/YYYY") ?? ""
          }`}
          onClick={() => setOpen(true)}
          variant="outlined"
        />

        {/* Filter Icon */}
        <CustomTooltip  title={"Sort"}>
        <IconButton onClick={toggleSorting}>
          <SwapVert />
        </IconButton>
        </ CustomTooltip>

        <Modal
          open={open}
          onClose={() => {
            handleCancel();
            setOpen(false);
          }}
        >
          <Box
            sx={{
              width: "60%",
              margin: "auto",
              marginTop: "10%",
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: 24,
            }}
          >
            <Box sx={{ padding: "20px" }}>
              <Typography variant="h6" align="center">
                Select Date Range
              </Typography>
            </Box>
            <Divider sx={{}} />

            <Box sx={{ display: "flex" }}>
              <List sx={{ flex: 1, borderRight: "1px solid #ddd", gap: 1 }}>
                {predefinedOptions.map((option: any) => (
                  <ListItem disablePadding key={option}>
                    <ListItemButton
                      selected={selectedOption === option}
                      onClick={() => handleOptionClick(option)}
                      sx={{
                        cursor: "pointer",
                        padding: "8px 16px",
                        borderRadius: 1,
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "rgba(32, 125, 156, 0.2)",
                          color: "rgb(32, 125, 156)",
                          "&:hover": {
                            backgroundColor: "rgba(32, 125, 156, 0.3)",
                          },
                        },
                      }}
                    >
                      {option}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Box sx={{ flex: 3, padding: "20px" }}>
                <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={tempRange[0]}
                    onChange={handleStartDateChange}
                    maxDate={dayjs()}
                    slotProps={{
                      nextIconButton: { size: 'small' },
                      previousIconButton: { size: 'small' },
                    }}
                    sx={{
                      width: "50%",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "rgb(32, 125, 156)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "rgb(32, 125, 156)",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        top: "-5px",
                      },
                    }}
                  />

                  {/* End Date Picker */}
                  <DatePicker
                    label="End Date"
                    value={tempRange[1]}
                    onChange={handleEndDateChange}
                    maxDate={dayjs()}
                    slotProps={{
                      nextIconButton: { size: 'small' },
                      previousIconButton: { size: 'small' },
                    }}
                    sx={{
                      width: "50%",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "rgb(32, 125, 156)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "rgb(32, 125, 156)",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        top: "-5px",
                      },
                    }}
                  />
                </Box>

                {isApplyButtonDisabled() && (
                  <Box sx={{ color: "red", marginTop: "10px" }}>
                    <Typography>
                      Please enter valid start and end dates.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Divider sx={{}} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleCancel();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  // Only allow the click if the button is not disabled
                  if (!isApplyButtonDisabled()) {
                    handleApply();
                    setOpen(false);
                  }
                }}
                sx={{ marginRight: 2 }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangeModal;
