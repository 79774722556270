import React, { useState, useEffect, useMemo } from "react";
import { Box, Divider } from "@mui/material";
import MainLayout from "../../layouts/main/main_layout";
import dayjs, { Dayjs } from "dayjs";
import SearchSection from "./SearchSection";
import PersonList from "./PersonList";
import RightSideLayout from "./RightSideLayout";
import { DateRange } from "@mui/x-date-pickers-pro";
import DateRangeModal from "./DateRangeFilter";
import { useQuery } from "react-query";
import httpClient from "../../utils/http";

interface Person {
  id: number;
  name: string;
  date: string;
}

export interface SessionsResponse {
  totalRecords: number;
  size:         number;
  page:         number;
  sessions:     Session[];
}

export interface Session {
  sessionId: string;
  userId:    string;
  username:  string;
  firstName: string;
  lastName:  string;
  createdAt: string;
}


const AllConversation: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedPerson, setSelectedPerson] = useState<string | null>(null);
  const [filteredPeopleList, setFilteredPeopleList] = useState<Person[]>([]);
  const peopleList: Person[] = [
    { id: 1, name: "John Doe", date: "2024-12-01" },
    { id: 2, name: "Jane Smith", date: "2024-12-02" },
    { id: 3, name: "Alice Johnson", date: "2024-12-03" },
    { id: 4, name: "Bob Brown", date: "2024-12-04" },
    { id: 5, name: "Chris Evans", date: "2024-12-05" },
    { id: 6, name: "Emma Watson", date: "2024-12-06" },
    { id: 7, name: "Michael Brown", date: "2024-11-15" },
    { id: 8, name: "Jessica Taylor", date: "2024-11-18" },
    { id: 9, name: "James King", date: "2024-10-25" },
    { id: 10, name: "Sarah Miller", date: "2024-11-01" },
    { id: 11, name: "David Wilson", date: "2024-11-10" },
    { id: 12, name: "Sophia Lewis", date: "2024-10-10" },
    { id: 13, name: "George Harris", date: "2024-09-20" },
    { id: 14, name: "Olivia Clark", date: "2024-09-15" },
    { id: 15, name: "Lucas Walker", date: "2024-12-01" },
    { id: 16, name: "Mason Scott", date: "2024-12-10" },
    { id: 17, name: "Lily Young", date: "2024-10-03" },
    { id: 18, name: "Benjamin Adams", date: "2024-08-15" },
    { id: 19, name: "Chloe Allen", date: "2024-07-30" },
    { id: 20, name: "Samuel Nelson", date: "2024-06-10" },
    { id: 21, name: "Zoe Carter", date: "2024-05-25" },
    { id: 22, name: "Charlotte Rodriguez", date: "2024-03-17" },
    { id: 23, name: "Amelia Green", date: "2024-02-22" },
    { id: 24, name: "Ethan Perez", date: "2024-04-11" },
    { id: 25, name: "Henry Lopez", date: "2024-01-01" },
    { id: 26, name: "Isabella Hall", date: "2024-12-15" },
    { id: 27, name: "Oliver White", date: "2024-11-05" },
    { id: 28, name: "Scarlett Baker", date: "2024-10-20" },
    { id: 29, name: "Jackson Gonzalez", date: "2024-09-30" },
    { id: 30, name: "Avery Turner", date: "2024-08-08" },
    { id: 31, name: "Sophia King", date: "2024-07-25" },
    { id: 32, name: "Elijah Miller", date: "2024-06-30" },
    { id: 33, name: "Mila Collins", date: "2024-12-25" },
    { id: 34, name: "Madison Mitchell", date: "2024-11-22" },
    { id: 35, name: "Carter Perez", date: "2024-10-15" },
    { id: 36, name: "Grace Roberts", date: "2024-09-05" },
    { id: 37, name: "Jack Stewart", date: "2024-08-25" },
    { id: 38, name: "Megan Harris", date: "2024-07-18" },
    { id: 39, name: "Owen Lee", date: "2024-06-22" },
    { id: 40, name: "Ellie Hall", date: "2024-05-10" },
    { id: 41, name: "Alexander Young", date: "2024-04-02" },
    { id: 42, name: "Harper Nelson", date: "2024-03-01" },
    { id: 43, name: "Liam Carter", date: "2024-02-12" },
    { id: 44, name: "Archer Morgan", date: "2024-01-10" },
    { id: 45, name: "Evelyn Lewis", date: "2024-12-18" },
    { id: 46, name: "Matthew Walker", date: "2024-11-28" },
    { id: 47, name: "Nathaniel Robinson", date: "2024-10-07" },
    { id: 48, name: "Aiden Cooper", date: "2024-09-22" },
    { id: 49, name: "Lily Adams", date: "2024-08-16" },
    { id: 50, name: "Ryan Foster", date: "2024-07-08" },
    { id: 51, name: "John Doe", date: "2024-11-29" },
  ];
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs("2024-01-01"),
    dayjs(),
  ]);
  const [tempRange, setTempRange] = useState<DateRange<Dayjs>>(dateRange);
  const [tempInput, setTempInput] = useState<string[]>([
    dateRange[0]?.format("MM/DD/YYYY") ?? "",
    dateRange[1]?.format("MM/DD/YYYY") ?? "",
  ]);
  const [selectedOption, setSelectedOption] = useState<string>("All Time");
  const [errorMessages, setErrorMessages] = useState<string[]>(["", ""]);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const predefinedOptions: string[] = [
    "All Time",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Last 90 Days",
    "Last Month",
    "Last 180 Days",
    "Last Year",
    "Custom",
  ];

  const handleOptionClick = (option: string): void => {
    const today = dayjs();
    let startDate: Dayjs | null = null;
    let endDate: Dayjs | null = null;

    switch (option) {
      case "All Time":
        startDate = dayjs("2024-01-01");
        endDate = today;
        break;
      case "Today":
        startDate = today;
        endDate = today;
        break;
      case "Yesterday":
        startDate = today.subtract(1, "day");
        endDate = today.subtract(1, "day");
        break;
      case "Last 7 Days":
        startDate = today.subtract(7, "days");
        endDate = today;
        break;
      case "Last 30 Days":
        startDate = today.subtract(30, "days");
        endDate = today;
        break;
      case "Last 90 Days":
        startDate = today.subtract(90, "days");
        endDate = today;
        break;
      case "Last Month":
        startDate = today.subtract(1, "month").startOf("month");
        endDate = today.subtract(1, "month").endOf("month");
        break;
      case "Last 180 Days":
        startDate = today.subtract(180, "days");
        endDate = today;
        break;
      case "Last Year":
        startDate = today.subtract(1, "year").startOf("year");
        endDate = today.subtract(1, "year").endOf("year");
        break;
      default:
        break;
    }

    setTempRange([startDate, endDate]);
    setTempInput([
      startDate?.format("MM/DD/YYYY") ?? "",
      endDate?.format("MM/DD/YYYY") ?? "",
    ]);
    setSelectedOption(option);
    setErrorMessages(["", ""]);
  };

  const handleStartDateChange = (newStartDate: Dayjs | null) => {
    if (newStartDate && tempRange[1]) {
      const updatedRange: [Dayjs, Dayjs] = [
        newStartDate,
        tempRange[1] as Dayjs,
      ];
      setSelectedOption("Custom");
      setTempRange(updatedRange);
    } else if (newStartDate) {
      setTempRange([newStartDate, tempRange[1]]);
    }
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {
    if (newEndDate && tempRange[0]) {
      const updatedRange: [Dayjs, Dayjs] = [tempRange[0] as Dayjs, newEndDate];
      setTempRange(updatedRange);
      setSelectedOption("Custom");
    } else if (newEndDate) {
      setTempRange([tempRange[0], newEndDate]);
    }
  };

  const isApplyButtonDisabled = (): boolean => {
    const [startDate, endDate] = tempRange;

    // Check if both start and end dates are valid Dayjs objects
    const isStartDateValid = startDate && startDate.isValid();
    const isEndDateValid = endDate && endDate.isValid();

    // Check if the end date is smaller than the start date
    const isEndDateSmaller =
      endDate && startDate && endDate.isBefore(startDate);

    // Return true (disable button) if either date is invalid or if the end date is smaller than the start date
    return (
      !isStartDateValid ||
      !isEndDateValid ||
      isEndDateSmaller ||
      startDate === null ||
      endDate === null
    );
  };

  const handleApply = (): void => {
    if (isApplyButtonDisabled()) return;
    setDateRange(tempRange);
  };

  const handleCancel = (): void => {
    setTempRange(dateRange);
    setTempInput([
      dateRange[0]?.format("MM/DD/YYYY") ?? "",
      dateRange[1]?.format("MM/DD/YYYY") ?? "",
    ]);
    setErrorMessages(["", ""]);
  };

  // const handleApplyFilters = () => {
  //   const filteredByDate = peopleList.filter((person) => {
  //     const personDate = new Date(person.date);
  //     const startDate = dateRange?.[0]?.toDate();
  //     const endDate = dateRange?.[1]?.toDate();

  //     if (!startDate || !endDate) {
  //       // console.error("Invalid date range");
  //       return false;
  //     }

  //     // Compare the dates after converting to Date
  //     return personDate >= startDate && personDate <= endDate;
  //   });

  //   const filteredBySearch = filteredByDate.filter((person) =>
  //     person.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   setFilteredPeopleList(filteredBySearch); // Update filtered people list
  // };

  // useEffect(() => {
  //   handleApplyFilters(); // Re-filter the list whenever the date or search query changes
  // }, [searchQuery, dateRange, peopleList]);


  // // Memoized sorted list to avoid re-sorting on every render
  // const sortedList = useMemo(() => {
  //   return [...filteredPeopleList].sort((a, b) => {
  //     const aDate = new Date(a.date).getTime();
  //     const bDate = new Date(b.date).getTime();

  //     // Ascending or Descending sort
  //     if (sortOrder === "asc") {
  //       return aDate - bDate; // Ascending
  //     } else {
  //       return bDate - aDate; // Descending
  //     }
  //   });
  // }, [filteredPeopleList, sortOrder]);


  const toggleSorting = () => {
    setSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
  };

  
  const {data} = useQuery<SessionsResponse>(["sessions",searchQuery,dateRange,sortOrder],async () => {
    return (await httpClient
      .get("/v1/admin/conversations", {
        params: {
          page: 1,
          size: 100,
          ...(searchQuery ? {search: searchQuery}:{}),
          ...(dateRange ? {from: dateRange[0]?.toISOString(),to: dateRange[1]?.toISOString()}:{}),
          ...(sortOrder ? {orderBy: sortOrder}:{}),
        }
      })).data.data;
  });

  return (
    <MainLayout>
      <Box
        sx={{
          height: "calc( 100vh - 100px )",
          display: { md: "flex", sm: "block" },
          flexDirection: {
            md: "row",
            sm: "column",
          },
        }}
      >
        {/* left  Section  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md: "25% ",
              sm: " 100%",
            },
            borderRight: "1px solid #ccc",
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          <SearchSection
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setDateRange={setDateRange}
          />
          <DateRangeModal
            dateRange={dateRange}
            tempRange={tempRange}
            tempInput={tempInput}
            errorMessages={errorMessages}
            selectedOption={selectedOption}
            predefinedOptions={predefinedOptions}
            handleOptionClick={handleOptionClick}
            isApplyButtonDisabled={isApplyButtonDisabled}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            toggleSorting={toggleSorting}
          />
          <Divider sx={{ mt: 2 }} />
          <PersonList
            data={data?.sessions || []}
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
          />
        </Box>
        {/* Right Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            // borderRadius: 2,
            // boxShadow: 1,
            width: {
              md: "75%",
              sm: "100%",
            },
            overflowY: "hidden",
          }}
        >
          <RightSideLayout
            selectedPerson={selectedPerson}
            data={data?.sessions || []}
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AllConversation;
