import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import httpClient from "../../utils/http";

interface User {
  id: string;
  email: string;
  role: string;
  createdAt: number;
  updatedAt: number;
}

interface ISession {
  authToken: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  user: User | null;
}

const SessionContext = createContext<ISession | null>(null);

export function SessionProvider({ children }: PropsWithChildren) {
  const [authToken, setAuthtoken] = useState<string | null>(localStorage.getItem("authToken") || null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
      if(authToken){
        localStorage.setItem("authToken",authToken);
      }else{
        localStorage.removeItem("authToken");
      }
  },[authToken]);

  const login = async (email: string, password: string): Promise<boolean> => {
    const response = await httpClient
      .post("/v1/admin/login", { email, password })
      .then(({ data }) => {
        if (data?.success) {
          const token = data.data.token;
          const user = data.data.user;

          setAuthtoken(token);
          setUser(user);

          toast.success("Login successful!");
          return true;
        } else {
          toast.error("Login failed. Please check your credentials.");
          return false;
        }
      })
      .catch((error) => {
        // toast.error("An error occurred while logging in.");
        console.error("Login error:", error);
        return false;
      });

    return response;
  };

  return (
    <SessionContext.Provider
      value={{
        authToken,
        login,
        user,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export default function useSession(): ISession {
  return useContext(SessionContext)!;
}
