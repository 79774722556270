import type { } from "@mui/x-charts/themeAugmentation";
import type { } from "@mui/x-data-grid/themeAugmentation";
import type { } from "@mui/x-date-pickers/themeAugmentation";
import type { } from "@mui/x-tree-view/themeAugmentation";
import MainGrid from "./MainGrid";
import MainLayout from "../../layouts/main/main_layout";

export default function Dashboard() {
  return (
    <MainLayout>
      <MainGrid />
    </MainLayout>
  );
}
