import * as React from "react";
import { styled, Theme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SelectContent from "./SelectContent";
import MenuContent from "./MenuContent";
import CardAlert from "./CardAlert";
import OptionsMenu from "./OptionsMenu";
import Logo from "./../assets/svgs/admin-logo.svg";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  position: "unset",
  p: 0,
  m: 0,
  height: "100%",
  overflow: "hidden",
  // display: 'block',
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  // mt: 10,
  [`& .${drawerClasses.paper}`]: {
    position: "unset",
    borderRadius: 16,
    overflow: "hidden",

    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export default function SideMenu() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "primary.dark",
          color: "white",

        },
      }}
    >
      <Box py={4} px={4} sx={{ display: "flex", justifyContent: "center" }}>
        <img src={Logo} width={"80%"} />
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
        <SelectContent />
      </Box> */}
      <Divider
        sx={{
          mx: 2,
          borderColor: "white",
        }}
      />
      <MenuContent />
      {/* <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt="Riley Carter"
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
            Admin
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            admin@airasia.com
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack> */}
    </Drawer>
  );
}
