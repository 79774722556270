import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSession from "../provider/session/session_provider"; 
interface PrivateRouteProps {
  element: ReactNode; 
}

const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const navigate = useNavigate();
  const { authToken } = useSession();

  useEffect(() => {
    if (!authToken) {
      navigate("/"); 
    }
  }, [authToken, navigate]);

return authToken ? <>{element}</> : null;
};

export default PrivateRoute;
