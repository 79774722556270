import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { Link, useLocation } from "react-router-dom";
import Logo from "./../assets/svgs/log-out.svg";
import Setting from "./../assets/svgs/setting_icon.svg";
import ConversationIcon from "./../assets/svgs/conversation-icon.svg";
import { AddAlertSharp } from "@mui/icons-material";

const mainListItems = [
  {
    text: "Configurations",
    icon: <img src={ConversationIcon} alt="conversation" />,
    path: "/configurations",
  },
  {
    text: "Conversation",
    icon: <img src={ConversationIcon} alt="conversation" />,
    path: "/conversation/all",
    hasSubItems: false,
  },
  {
    text: "Alerts",
    icon: <AddAlertSharp />,
    path: "/alerts",
    hasSubItems: false,
  },
];

const secondaryListItems = [
  { text: "Settings", icon: <img src={Setting} alt="Setting" /> },
  { text: "Logout", icon: <img src={Logo} alt="Logout" /> },
];

export default function MenuContent() {
  const { pathname } = useLocation();

  return (
    <Stack sx={{ flexGrow: 1, p: 2, justifyContent: "space-between" }}>
      <List>
        {mainListItems.map((item, index) =>
          item.hasSubItems ? (
            <Box key={index} sx={{ mb: 2 }}>
              <ListItem
                disablePadding
                sx={{ display: "block", color: "inherit" }}
              >
                <ListItemButton
                  color="inherit"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "transparent",
                      opacity: 1,
                    },
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          ) : (
            <Link
              to={item.path}
              style={{ textDecoration: "none", color: "inherit" }}
              key={index}
            >
              <Box sx={{ mb: 2 }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={pathname === item.path}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "transparent",
                        opacity: 1,
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        color: "white",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
            </Link>
          )
        )}
      </List>

      <List>
        {secondaryListItems.map((item, index) => (
          <Box sx={{ mb: 2 }} key={index}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  opacity: 1,
                  "&:hover": {
                    backgroundColor: "transparent",
                    opacity: 1,
                  },
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: "transparent",
                    opacity: 1,
                  },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Stack>
  );
}
