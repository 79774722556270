import React, { useEffect, useState } from "react";
import { Edit, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import MainLayout from "../../layouts/main/main_layout";
import useFeedback from "../../provider/feedback/feedback_provider";
import httpClient from "../../utils/http";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CustomTextField from "./CustomTextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GridCellParams } from "@mui/x-data-grid";

type AlertConfig = {
  id: number;
  messages: {
    [language: string]: string;
  };
  startAt: string;
  endAt: string;
  isActive: boolean;
};

const languages = [
  "en-GB",
  "zh-CN",
  "zh-TW",
  "id-ID",
  "ms-MY",
  "th-TH",
  "ko-KR",
  "ja-JP",
  "km-KH",
  "tr-TR",
  "vi-VN",
  "ru-RU",
];

export default function Alerts() {
  const { showSnackbar } = useFeedback();
  const [addAlert, setAddAlert] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<AlertConfig>({
    id: 0,
    messages: {
      "en-GB": "",
      "zh-CN": "",
      "zh-TW": "",
      "id-ID": "",
      "ms-MY": "",
      "th-TH": "",
      "ko-KR": "",
      "ja-JP": "",
      "km-KH": "",
      "tr-TR": "",
      "vi-VN": "",
      "ru-RU": "",
    },
    startAt: "",
    endAt: "",
    isActive: false,
  });
  const [editAlertId, setEditAlertId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const [switchChange, setSwitchChange] = useState<boolean>(false);

  const {
    data: alerts,
    isLoading,
    isError,
    refetch: refetchAlerts,
  } = useQuery(
    "alerts",
    async () => {
      const response = await httpClient.get("v1/admin/alerts");
      return response.data.data.map((alert: any) => ({
        ...alert,
        messages: {
          "en-GB": alert.message?.["en-GB"] || "",
          "zh-CN": alert.message?.["zh-CN"] || "",
          "zh-TW": alert.message?.["zh-TW"] || "",
          "id-ID": alert.message?.["id-ID"] || "",
          "ms-MY": alert.message?.["ms-MY"] || "",
          "th-TH": alert.message?.["th-TH"] || "",
          "ko-KR": alert.message?.["ko-KR"] || "",
          "ja-JP": alert.message?.["ja-JP"] || "",
          "km-KH": alert.message?.["km-KH"] || "",
          "tr-TR": alert.message?.["tr-TR"] || "",
          "vi-VN": alert.message?.["vi-VN"] || "",
          "ru-RU": alert.message?.["ru-RU"] || "",
        },
        startAt: dayjs(alert.startAt).format("DD MMM YYYY hh:mm A"),
        endAt: dayjs(alert.endAt).format("DD MMM YYYY hh:mm A"),
        isActive: alert.isActive,
      }));
    },
    {
      initialData: [],
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const { mutate: createAlert } = useMutation(
    async () => {
      const response = await httpClient.post("/v1/admin/alerts", {
        message: formValues.messages,
        startAt: formValues.startAt,
        endAt: formValues.endAt,
        isActive: formValues.isActive,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        refetchAlerts();
        showSnackbar("Alert added successfully!", "success");
        setAddAlert(false);
        resetForm();
      },
      onError: (error: any) => {
        showSnackbar(
          `Failed to add alert: ${
            error?.response?.data?.message || "Unknown error"
          }`,
          "error"
        );
      },
    }
  );

  const { mutate: updateAlert } = useMutation(
    async () => {
      const response = await httpClient.patch(
        `/v1/admin/alerts/${editAlertId}`,
        {
          message: formValues.messages,
          startAt: formValues.startAt,
          endAt: formValues.endAt,
          isActive: formValues.isActive,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        refetchAlerts();
        showSnackbar("Alert updated successfully!", "success");
        setAddAlert(false);
        resetForm();
        setEditAlertId(null);
      },
      onError: (error: any) => {
        showSnackbar(
          `Failed to update alert: ${
            error?.response?.data?.message || "Unknown error"
          }`,
          "error"
        );
      },
    }
  );

  const { mutate: deleteAlert } = useMutation(
    async (id: number) => {
      const response = await httpClient.delete(`/v1/admin/alerts/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        refetchAlerts();
        showSnackbar("Alert deleted successfully!", "success");
      },
      onError: (error: any) => {
        showSnackbar(
          `Failed to delete alert: ${
            error?.response?.data?.message || "Unknown error"
          }`,
          "error"
        );
      },
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === "isActive" ? value === "true" : value,
    });
  };

  const handleAddAlert = () => {
    createAlert();
  };

  useEffect(() => {
    if (switchChange) {
      updateAlert();
      setSwitchChange(false);
    }
  }, [switchChange]);

  const handleEditAlert = (alert: AlertConfig) => {
    setFormValues({
      id: alert.id,
      messages: alert.messages,
      startAt: alert.startAt,
      endAt: alert.endAt,
      isActive: alert.isActive,
    });
    setEditAlertId(alert.id);
    setAddAlert(true);
  };

  const handleDeleteAlert = (id: number) => {
    deleteAlert(id);
  };

  const resetForm = () => {
    setFormValues({
      id: 0,
      messages: {
        "en-GB": "",
        "zh-CN": "",
        "zh-TW": "",
        "id-ID": "",
        "ms-MY": "",
        "th-TH": "",
        "ko-KR": "",
        "ja-JP": "",
        "km-KH": "",
        "tr-TR": "",
        "vi-VN": "",
        "ru-RU": "",
        // Add other languages as needed
      },
      startAt: "",
      endAt: "",
      isActive: false,
    });
  };

  const handleSwitchChange = (alert: AlertConfig) => {
    setFormValues({
      id: alert.id,
      messages: alert.messages,
      startAt: alert.startAt,
      endAt: alert.endAt,
      isActive: !alert.isActive,
    });
    setEditAlertId(alert.id);
    setSwitchChange(true);
  };

  const columns: GridColDef[] = [
    {
      field: "messages",
      headerName: "Messages",
      width: 360,
      renderCell: (params) => {
        const messages = params.row.message;
        const formattedMessages = Object.keys(messages)
          .map((lang) => ` ${messages[lang]}`)
          .join(", ");
        return formattedMessages || "No messages";
      },
    },
    { field: "startAt", headerName: "Start At", width: 200 },
    { field: "endAt", headerName: "End At", width: 200 },
    {
      field: "isActive",
      headerName: "Active",
      width: 150,
      renderCell: (row) => {
        return (
          <Switch
            checked={row.row.isActive}
            onChange={() => handleSwitchChange(row.row)}
            color="primary"
            inputProps={{
              "aria-label": "active status",
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (row) => (
        <Box>
          <IconButton onClick={() => handleEditAlert(row.row)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteAlert(row.row.id)}>
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching alerts!</div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainLayout>
        <Box sx={{ width: "100%" }}>
          <Box display={"flex"} justifyContent={"space-between"} mt={2} mb={2}>
            <Typography component="h2" variant="h6">
              Alerts
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setEditAlertId(null);
                setAddAlert(true);
                resetForm();
              }}
            >
              Add Alerts
            </Button>
          </Box>

          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            rows={alerts || []}
            columns={columns}
            getRowId={(row) => row.id}
          />
        </Box>

        <Dialog onClose={() => setAddAlert(false)} open={addAlert} fullWidth>
          <DialogTitle>
            {editAlertId ? "Update Alerts" : "Add Alerts"}
          </DialogTitle>
          <DialogContent>
            <Grid2 container spacing={2} mt={2}>
              {languages.map((lang, index) => (
                <Grid2 key={lang} size={{ md: 12 }}>
                  <CustomTextField
                    id={`messages-${lang}`}
                    label={`Message for ${lang}`}
                    name={`messages-${lang}`}
                    placeholder={`Enter message for ${lang}`}
                    value={formValues.messages?.[lang] || ""}
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        messages: {
                          ...formValues.messages,
                          [lang]: event.target.value,
                        },
                      })
                    }
                    required
                  />
                </Grid2>
              ))}
              <Grid2 size={{ md: 12 }}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="startAt">Start At</FormLabel>

                  <DateTimePicker
                    value={
                      formValues.startAt ? dayjs(formValues.startAt) : null
                    }
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        startAt: date ? date.format("YYYY-MM-DD HH:mm:ss") : "",
                      })
                    }
                    maxDate={dayjs()}
                    slotProps={{
                      nextIconButton: { size: "small" },
                      previousIconButton: { size: "small" },
                    }}
                    sx={{
                      width: "100%",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#0a393f",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#0a393f",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        top: "-5px",
                      },
                    }}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{ md: 12 }}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="endAt">End At</FormLabel>
                  <DateTimePicker
                    value={formValues.endAt ? dayjs(formValues.endAt) : null}
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        endAt: date ? date.format("YYYY-MM-DD HH:mm:ss") : "",
                      })
                    }
                    maxDate={dayjs()}
                    slotProps={{
                      nextIconButton: { size: "small" },
                      previousIconButton: { size: "small" },
                    }}
                    sx={{
                      width: "100%",
                      "& .MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#0a393f",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#0a393f",
                        },
                      },

                      "& .MuiInputLabel-root": {
                        top: "-5px",
                      },
                    }}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{ md: 12 }}>
                <FormControl fullWidth>
                  {/* <FormLabel htmlFor="isActive">Active</FormLabel> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.isActive}
                        onChange={(event) => {
                          handleInputChange({
                            ...event,
                            target: {
                              ...event.target,
                              name: "isActive",
                              value: event.target.checked.toString(),
                            },
                          });
                        }}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label={formValues.isActive ? "Active" : "Inactive"}
                  />
                </FormControl>
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setAddAlert(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => (editAlertId ? updateAlert() : handleAddAlert())}
            >
              {editAlertId ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </MainLayout>
    </LocalizationProvider>
  );
}
