import { Tooltip, TooltipProps } from '@mui/material';
import { ReactElement } from 'react';

interface CustomTooltipProps extends Omit<TooltipProps, 'children'> {
  children: ReactElement;
  title: string;
}

const CustomTooltip = ({ children, title, ...props }: CustomTooltipProps) => {
  return (
    <Tooltip
      title={title}
      arrow
      enterDelay={500}
      leaveDelay={200} 
      {...props}
      sx={{
        '& .MuiTooltip-tooltip': {
          fontSize: '1.2rem', 
          padding: '8px 12px',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          borderRadius: '4px',
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
