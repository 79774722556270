import { useSnackbar } from "notistack";

export default function useFeedback() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    showSnackbar: function (
      message: string,
      variant: "default" | "error" | "success" = "default"
    ) {
      enqueueSnackbar(message, { variant });
    },
    closeSnackbar
  };
}
